<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" class="row hero-content">
					<div class="columns column12">
						<div class="content">
							<p class="hero-subtitle">
								<span>{{ defaults[locale].homepage.headingSubTitle }}</span>
							</p>
							<p class="hero-title">
								<span>{{ defaults[locale].homepage.headingTitle }}</span>
							</p>
						</div>
					</div>
				</div>
			</template>
		</heading-image>

		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:content-full-width="page.contentFullWidth"
		/>

		<section class="booking-benefits">
			<div class="row">
				<div class="columns column4 right bg-image-content">
					<h2>{{ defaults[locale].homepage.sectionBenefitsTitle }}</h2>
					<ul>
						<li v-for="(benefit, key) in defaults[locale].homepage.bookingBenefits" :key="key">
							<div v-if="benefit.icon == 'logo-icon'" class="benefits-list-icon" />
							<font-awesome-icon v-else :icon="`far fa-${benefit.icon}`" size="1x" />
							<span>{{ benefit.bookingBenefit }}</span>
						</li>
					</ul>
					<book-button class="button button-cta reserve-now">
						{{ $t('bookNow') }}
					</book-button>
				</div>
			</div>
			<picture>
				<source
					v-if="defaults[locale].homepage.sectionBenefitsImageWebp"
					:srcset="defaults[locale].homepage.sectionBenefitsImageWebp"
					type="image/webp"
				/>
				<source :srcset="defaults[locale].homepage.sectionBenefitsImage" />
				<img
					class="bg-image"
					:src="defaults[locale].homepage.sectionBenefitsImage"
					:alt="defaults[locale].homepage.sectionBenefitsImageAlt"
					loading="lazy"
				/>
			</picture>
		</section>

		<promoblocks :items="promoblocksData" :amount="3">
			<template #header>
				<div
					v-parse-links
					class="columns column12"
					v-html="defaults[locale].homepage.sectionPromotionContent"
				/>
			</template>
		</promoblocks>

		<contentblocks v-if="page.contentblocks && page.contentblocks.length" :items="page.contentblocks" />

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />
		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	right: 0;
	z-index: 4;
	width: 770px;
	max-width: 80%;
	padding: 0;

	.column12 {
		padding: 0;
	}

	.content {
		color: #fff;
		text-align: center;
		margin: 0 auto;
		animation: fade-in 1500ms ease-out;
		font-family: $heading-font-family;
	}

	p {
		margin: 0;
	}

	.hero-title {
		font-size: 3.2em;
		line-height: 120%;
		color: #fff;
		font-weight: 300;
		text-transform: uppercase;
	}

	.hero-subtitle {
		font-weight: 400;
		font-size: 20px;
		line-height: 100%;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		color: #fff;
	}
}

.booking-benefits {
	position: relative;
	padding: 0 0 450px;
	margin: 0 0 75px;
	background: $alternating-background-color;
	overflow: visible;

	.row {
		position: relative;
		padding-top: 200px;
		overflow: visible;
	}

	ul {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;
			line-height: 36px;
			vertical-align: middle;
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			margin: 10px 0;
			font-size: calc($body-font-size + 1px);

			svg {
				margin: 0 7px 0 0;
				font-size: 20px;
				display: inline-block;
				color: $cta-color;
				width: 30px;
			}

			.benefits-list-icon {
				display: block;
				width: 30px;
				height: 30px;
				background: url('~/assets/images/pre-subtitle-icon.png') no-repeat center center;
				background-size: 30px;
				margin: 0 10px 0 0;
			}
		}
	}
}

.bg-image {
	position: absolute;
	inset: 75px 32% 0 0;
	height: 100%;
	width: 68%;
	object-fit: cover;
	z-index: 1;
}

.bg-image-content {
	background: $contentblock-background-color;
	padding: 50px;
	z-index: 3;
}

@media (max-width: 880px) {
	.booking-benefits {
		padding: 50px 0;
		margin: 0;
		display: flex;
		flex-flow: column-reverse wrap;

		.row {
			position: auto;
			padding-top: 0;
			overflow: visible;
			margin-top: -40px;
			width: 98%;

			&::before {
				top: -70px;
				background: url('~/assets/images/border-overlay-bg-pattern.png') no-repeat bottom 100% left;
				background-size: 150px;
				width: 150px;
				height: 141px;
			}
		}

		.bg-image-content {
			width: 100%;
			padding: 50px 30px;
		}

		.bg-image {
			position: relative;
			inset: auto;
			height: auto;
			width: 90%;
			object-fit: cover;
			z-index: 1;
		}
	}
}

@media (max-width: 600px) {
	.hero-image {
		.hero-content {
			.hero-title {
				font-size: 3em;
			}
		}
	}
}

@media (max-width: 500px) {
	.hero-image .hero-content {
		.hero-subtitle {
			font-size: 14px;
		}

		.hero-title {
			font-size: 30px;
		}
	}
}
</style>
